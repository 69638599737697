export const urls = {
  about: {
    index: "/about",
    faq: "/about/faq",
  },
  diversity: "/metrics",
  explore: "/explore",
  faq: "/faq",
  profile: "/profile",
  profileCountry: "/profile/country",
  profileTopic: "/profile/topic",
  root: "/",
  search: {
    landing: "/search",
    results: "/search/results",
  },
};
